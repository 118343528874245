<template>
    <div class="content" v-if="this.ViewType==='nineN'">
          
          <span class="title">
            第九届“大唐杯”全国大学生新一代信息通信技术大赛（南区）全国总决赛成功举办
          </span>
          <div class="contbox">
            <p class="text_t"> 2022年7月17日，第九届“大唐杯”全国大学生新一代信息通信技术大赛（南区）全国总决赛在武汉职业技术学院落下帷幕。中信科移动通信技术股份有限公司党委书记、董事长罗昆初、工信部人才交流中心副主任曾卫明、中国通信协会副秘书长赵俊湦、武汉职业技术学院党委书记李洪渠、全国工业和信息化通信职业教育教学指导委员会副主任委员王汝言、湖北省高等教育学会高校计算机教育专业委员会理事会副主任委员陈晴、大唐杯全国大学生新一代信息通信技术大赛区域委员会主任委员李俊、大唐杯全国大学生新一代信息通信技术大赛区域委员会主任委员雷菁及中信科移动人力资源部总经理李诣、中信科移动营销中心总经理赵力、中信科移动客服中心总经理蒋珀等南部赛区校企的相关领导出席颁奖典礼。</p>
              <div class="item_img">
              <img src="@/assets/images/n1.jpg" />
            </div>
            <p class="text_t">与会嘉宾就本届大赛对深度产教融合、信息通信技术发展、产业数字化转型赋能、协同育人等方面的促进作用和社会贡献给予了高度评价。尤其在喜迎中国共产党第二十次全国代表大会的伟大历史时刻，对大赛的成功举办表达了祝贺。</p>
            <p class="text_t">嘉宾们认为新一轮科技革命和产业变革正加速演进，并深刻改变着世界格局。能否掌握核心技术，不仅影响着全球产业布局，也决定着国家民族的前途命运。习总书记在武汉视察中信科移动所属集团——中国信科集团时强调：“核心技术、关键技术、国之重器必须立足于自身”，科技攻关“要摒弃幻想、靠自己”。当下，培养全方位战略性人才，是我国“产业基础高级化和产业链现代化”协同发展的重要支撑，更是在充满挑战的时代加速数字化转型的重要引擎。</p>
            <div class="item_img">
              <img src="@/assets/images/n2.jpg" />
            </div>
            <p class="text_t">中信科移动作为本次大赛承办单位，积极顺应数字化转型的时代浪潮，以“加速5G建设，服务5G新场景，赋能5G新应用”为己任，不断深化同全国高校在5G通信技术实训平台、双师培养、高质量课程体系建设、5G+垂直产业创新应用等方面的深度融合，助力高校实现卓越人才培养和教学质量双提升，实现“企业用人”与“高校育人”双循环。同时，作为以5G为代表的移动通信领域领军企业，中信科移动也将围绕客户体验，进一步拓展国内外信息通信产业格局；坚持自主创新驱动价值创造，持续打造移动通信领域的核心技术、国之重器、创新高地，为全球电信运营商和行业客户提供领先的移动通信技术、信息通信产品与综合服务解决方案。</p>
            <p class="text_t">与会各单位领导一致表示，大家要以“大唐杯”为纽带，继续携手工信部人才交流中心、中国通信企业协会、北京高校电子信息类专业群、北方工业大学、北京邮电大学、武汉职业技术学院，以及参与“大唐杯”的所有高校和兄弟单位，持续构建信息通信产业与数字人才发展生态圈。为新时代数字化人才培养和深度产教融合做出更大努力。</p>
            <div class="item_img">
              <img src="@/assets/images/n3.jpg" />
            </div>
            <p class="text_t">为践行以赛促教、以赛促学的办赛理念，为新一代信息通信技术人才培养提供平台支撑，实现校企联合育人、产教深度融合，培养符合国家需要的全方位战略性人才，助力国家信息产业发展，本届大赛进一步丰富了“政、产、学、研、用”的育人内涵，推动实现了专业与产业、教学与岗位、课程与生产、教材与项目四对齐，实现了竞赛环境、育人平台、教学体系、评估机制的人才培养模式四创新，实现了专业教学与产业工程、传统学科与新兴学科、育人机制与项目管理、人才培养与社会服务的校企合作四融合。此次大赛覆盖全国30个省市自治区,参与大赛的院校数量达400余所，包括北京邮电大学、电子科技大学、北京航空航天大学、哈尔滨工业大学、中山大学、重庆邮电大学、国防科技大学等一批双一流建设院校，和南京工业职业技术大学、武汉职业技术学院、福建信息职业技术学院、重庆电子工程职业学院等多所教育部获批的双高建设职业院校。参赛师生共计2.5万余名，创下历届大赛参赛人数新高。2500余名选手经过省赛激烈的选拔后脱颖而出，以线上或线下的方式进行全国总决赛桂冠的争夺。本届大赛分为本科A组、本科B组、高职组、研究生赛、一带一路留学生赛五个类别，同时独立设置双师型教师赛。大赛以真设备、真场景、真问题作为竞赛核心，选手在5G技术原理、5G工程实践及5G+创新应用三方面进行了真设备实践、真场景应用、真问题解决等工程能力比拼</p>
            <div class="item_img">
              <img src="@/assets/images/n4.jpg" />
            </div>
            <p class="text_t">颁奖典礼结束前，与会嘉宾和大赛区委会委员代表共同参加了南部赛区区域委员会的启动仪式。</p>
            <p class="text_t">延伸延读：


            颁奖典礼前，来自电子科技大学、中山大学、江西应用科技学院、重庆邮电大学、武汉职业技术学院等院校电子信息类专业领域的25名权威教学专家共同参加了“大唐杯”全国大学生新一代信息通信技术大赛南部赛区委员会的成立大会。会上委员代表针对区委会在人才培养的质量提升、赛事影响力提升等方面进行精彩发言。会议最后中信科移动客服中心总经理蒋珀宣读了区委会章程。
            </p>
            <div class="item_img">
              <img src="@/assets/images/n5.jpg" />
            </div>
            <p class="text_t">大赛期间，大赛组委会围绕新一代信息技术发展及未来、专业建设、应用型人才培养、卓越人才培养、大唐杯竞赛云数据分析等主题组织了由南区参赛院校教师参加的新时代数字人才赋能高峰论坛。</p>
            <div class="item_img">
              <img src="@/assets/images/n6.jpg" />
            </div>
            <div class="item_img">
              <img src="@/assets/images/n7.jpg" />
            </div>
            <p class="text_t">大赛期间，中信科移动与武汉职业技术学院共同建设的5G+产教融合基地为到校嘉宾和老师们提供了丰富多彩的参观交流活动。</p>
            <div class="img_box">
            </div>
          </div>
        </div>
</template>
<script>
export default {
    props:['ViewType'],
    data(){
        return {

        }
    }
}
</script>
<style scoped lang="scss">

  @import './Brief.scss';
  </style>