<template>
    <div class="content" v-if="this.ViewType==='play'">
          
          <span class="title">
           现场直播 | 第九届“大唐杯”全国大学生新一代信息通信技术大赛全国总决赛闭幕式暨颁奖典礼
          </span>
         <p class="desc">第九届“大唐杯”全国大学生新一代信息通信技术大赛
            全国总决赛闭幕式暨颁奖典礼（南部区域）
        </p>
        <span class="desc1">直播时间</span>
        <span class="desc2">2022年7月17日 14:00-16:00</span>
          <div class="contbox">
              <div class="item_img">
              <img src="@/assets/images/playV.jpg" />
            </div>
            <div class="img_box">
            </div>
          </div>
        </div>
</template>
<script>
export default {
    props:['ViewType'],
    data(){
        return {

        }
    }
}
</script>
<style scoped lang="scss">
.desc {
    margin-top:0.1rem;
    width:100%;
    display:block;
    text-align: center;
    color:red;
    font-family: Poppins-SemiBold;
    font-size: .24rem;
    font-weight: 600;
}
.desc1 {
    margin-top:0.1rem;
    width:100%;
    display:block;
    text-align: center;
    font-family: Poppins-SemiBold;
    font-size: .24rem;
    font-weight: 600;
}
.desc2 {
    margin-top:0.1rem;
    width:100%;
    display:block;
    text-align: center;
    color:red;
    font-family: Poppins-SemiBold;
    font-size: .24rem;
    font-weight: 600;
}
  @import './Brief.scss';
  </style>