<template>
    <div class="content" v-if="this.ViewType==='nineW'">
          
          <span class="title">
           第九届“大唐杯”全国大学生移动通信5G技术大赛省赛成功举办
          </span>
          <div class="contbox">
            <p class="text_t"> 2022年4月10日~16日，经过大唐杯组委会数月的精心筹备与全国各赛区激烈的角逐，第九届“大唐杯”全国大学生移动通信5G技术大赛省赛如期圆满落幕。自荣登高等教育学会“全国大学生竞赛排行榜”榜单以来，大赛持续保持高质量，高水平。第九届大唐杯省赛报名选手近2.5万人，覆盖全国30个省市自治区、共有400所余所院校参赛，3500余名指导教师参与，参赛人数与院校均创下了历年来的纪录。</p>
              <div class="item_img">
              <img src="@/assets/images/nW1.jpg" />
            </div>
            <p class="text_t">第九届“大唐杯”进一步完善升级了赛事管理水平，对赛道进行细分，更注重综合应用人才培养，对不同需求匹配开放性的创新实践竞赛方案。大赛始终贯彻“以赛促学、以赛促教、以赛促练、以赛促建、以赛促改”的原则，结合行业最新发展方向，实现与高校在产教融合与人才培养的综合性需求。</p>
            <div class="item_img">
              <img src="@/assets/images/nW2.jpg" />
            </div>
            <p class="text_t">为保障大赛顺利进行，大唐杯组委会制定了充分的疫情防控方案，全国各赛区均成立了大唐杯临时保障小组，对大赛各项工作进行了全面组织协调与部署。</p>
            <div class="item_img">
              <img src="@/assets/images/nW3.jpg" />
            </div>
            <p class="text_t">自2014年开办以来，“大唐杯”全国大学生移动通信5G技术大赛已成功举办九届，大赛是由工信部人才交流中心与中国通信企业协会主办，中信科移动通信技术有限公司、北京市教委北京高校电子信息类专业群共同组织承办的全国性创新实践型的竞赛。也是教育部高等教育学会“全国大学生竞赛排行榜”内排名最前的信息通信类专业竞赛。</p>
            <div class="item_img">
              <img src="@/assets/images/nW4.jpg" />
            </div>
            <p class="text_t">大赛以在校大学生为主体参与者，以提升学生专业技能、协同高校学科建设、推动行业创新发展为目的，依托中信科移动深厚的技术底蕴，实现了竞赛从理论培训、实操模拟到工程实践的全流程突破，促进 DOICT 相关专业教学内容和教学方法的改革创新，提升高校毕业生的就业竞争力，为移动通信及相关行业输送具有创新能力和实践能力的高端人才。</p>
            <div class="img_box">
            </div>
          </div>
        </div>
</template>
<script>
export default {
    props:['ViewType'],
    data(){
        return {

        }
    }
}
</script>
<style scoped lang="scss">

  @import './Brief.scss';
  </style>