<template>
    <div class="content" v-if="this.ViewType==='nineB'">
          
          <span class="title">
           第九届“大唐杯”全国大学生新一代信息通信技术大赛（北区）全国总决赛成功举办
          </span>
          <div class="contbox">
            <p class="text_t"> 2022年8月7日，第九届“大唐杯”全国大学生新一代信息通信技术大赛（北区）全国总决赛在北方工业大学落下帷幕。中信科移动通信技术股份有限公司副总经理蔡鑫、工业和信息化部人才交流中心副主任色云峰、中国通信协会副秘书长赵俊湦、北方工业大学副校长栗苹、北京邮电大学教务处处长卞佳丽及北部赛区院校、企业相关领导出席颁奖典礼。与会嘉宾就本届大赛在新一代信息技术创新方案、新工科人才发展、产业数字化转型赋能等方面的促进作用和社会贡献给予了高度评价。</p>
              <div class="item_img">
              <img src="@/assets/images/nB1.jpg" />
            </div>
            <p class="text_t">与会嘉宾在颁奖典礼致词时表示新一代信息技术已成为支撑所有工业数字化转型的基础底盘，5G作为信息技术的核心技术，不仅影响着全球产业布局，也决定着国家民族的前途命运。当代人才培养应该顺应数字化转型的时代浪潮，以“加速5G建设，服务5G新场景，赋能5G新应用”为己任，不断深化同校企在5G通信技术实训平台、双师培养、高质量课程体系建设、5G+垂直产业创新应用等方面的深度融合，持续推动高校实现卓越人才培养和教学质量双提升，实现“企业用人”与“高校育人”双循环。</p>
            <div class="item_img">
              <img src="@/assets/images/nB2.jpg" />
            </div>
            <p class="text_t">面对我国经济发展的数字化化转型挑战，我们需要进一步拓展国内外信息通信产业格局，坚持自主创新驱动价值创造，持续打造移动通信领域的核心技术、国之重器、创新高地。大家一致认为未来应以“大唐杯”为纽带，继续扩大参与“大唐杯”高校和企业单位的朋友圈，持续构建信息通信产业与数字人才发展良性生态，为新时代数字化人才培养和深度产教融合做出更大贡献。</p>
            <div class="item_img">
              <img src="@/assets/images/nB3.jpg" />
            </div>
            <p class="text_t">颁奖典礼上与会嘉宾和大赛区委会委员代表共同参加了大赛北部区域委员会的启动仪式。</p>
            <div class="item_img">
              <img src="@/assets/images/nB4.jpg" />
            </div>
            <p class="text_t">大赛期间，区委会专家在中信科移动通信技术股份有限公司移动通信事业部总经理王利的陪同下饶有兴趣的参观了中信科移动位于西二旗的国家重点实验室。专家们对企业在技术创新、标准引领和人才培养等方面作出的贡献表示了充分认可和称赞。极大增强了中信科移动在教育教学领域的科技影响力，为深度校企融合奠定了基础。</p>
            <div class="item_img">
              <img src="@/assets/images/nB5.jpg" />
            </div>
            <p class="text_t">会前来自北京邮电大学、北京航空航天大学、哈尔滨工业大学、青岛大学等院校电子信息类专业领域的30多名权威教学专家共同参加了“大唐杯”全国大学生新一代信息通信技术大赛北部赛区委员会的成立大会。会上委员代表针对人才培养质量提升、新工科专业建设创新思路、虚拟教研室建设创新、双师型教师人才培养及工程教育专业认证经验等方面进行精彩发言。</p>
            <div class="item_img">
              <img src="@/assets/images/nB6.jpg" />
            </div>
            <p class="text_t"> 为践行以赛促教、以赛促学的办赛理念，为新一代信息通信技术人才培养提供平台支撑，实现校企联合育人、产教深度融合，培养符合国家需要的全方位战略性人才，助力国家信息产业发展，本届大赛进一步丰富了“政、产、学、研、用”的育人内涵，吸引了2.5万余名学生参赛，创下历届大赛参赛人数新高。在人才培养和产业生态培育方面极大提升了大唐杯的品牌影响力。</p>
            <div class="img_box">
            </div>
          </div>
        </div>
</template>
<script>
export default {
    props:['ViewType'],
    data(){
        return {

        }
    }
}
</script>
<style scoped lang="scss">

  @import './Brief.scss';
  </style>